import styled, {css} from 'styled-components';
import { Link } from 'gatsby';
import * as presets from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { breakpoints } from '../../styles/layout';
import { fonts } from '../../styles/typography';


const style = css`
  ${presets.typeN14r};
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid ${colors.darkBlue};
  color: ${colors.darkBlue};
  &:after {
    content: ' →';
    font-family: ${fonts.national.family};
  }
  @media(min-width: ${breakpoints.lg}) {
    ${presets.typeN16r};
  }
`;



export const MoreLink = styled(Link)`
  ${style};
`;

export const MoreAnchor = styled.a`
  ${style};
`;

export default MoreLink;