import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import * as presets from '../../styles/typography/presets';
import { typeN16r, typeT21sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { MoreLink } from '../shared/MoreLink';


const Icon = styled.div`

`;

const Title = styled.div`
  ${presets.typeT16sb};
`;

const Brief = styled.div`
  ${presets.typeN14r};
`;

const LinkComponent = styled.div`

`;

const Text = styled.div`
`;

const Block = styled.li`
  ${Icon} {
    margin-bottom: 12px;
    padding-left: 27px;
    display: flex;
    align-items: flex-end;
    width: 210px;
    height: 96px;
    img {
      max-height: 96px;
      max-width: 210px;
    }
  }
  
  ${Text} {
    position: relative;
    padding-left: 27px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 90px;
      background: ${colors.grey};
      width: 1px;
    }
  }
  
  ${LinkComponent} {
    margin-top: 21px;
  }
`;

const Blocks = styled.ul`
  grid-column: 2 / span 8;
  
  ${Block} {
    margin-bottom: 90px;
    max-width: 390px;
    &:last-child {
      margin-bottom: 0;
    }
    ${Icon} {
      margin-bottom: 30px;
    }
    
    ${Text} {
      ${LinkComponent} {
        margin-top: 12px;
      }
    }
  }
  
  @media(min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 45px;
    grid-row-gap: 90px;
    
    ${Block} {
      margin-bottom: 0;
      ${Icon} {
        margin-bottom: 30px;
      }
      ${Text} {
        ${LinkComponent} {
          margin-top: 24px;
        }
      }
    }
  }
  
  @media(min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 75px;
    grid-row-gap: 120px;
    
    ${Title} {
      ${typeT21sb};
    }
    
    ${Brief} {
      ${typeN16r};
    }
    
    ${MoreLink} {
      ${typeN16r};
    }
    
    ${Block} {
      ${Icon} {
        margin-bottom: 30px;
      }
    }
  }
`;

const CustomContainer = styled(Container)`
`;


export default ({ blocks, ...props }) => (
  <CustomContainer {...props}>
    <Blocks>
      {blocks.map(block => (
        <Block key={block.title}>
          <Icon>
            <img src={block.icon.url}/>
          </Icon>
          <Text>
            <Title>{block.title}</Title>
            <Brief>{block.brief}</Brief>
            {block.linkComponent && (
              <LinkComponent>
                {block.linkComponent}
              </LinkComponent>
            )}
          </Text>
        </Block>
      ))}
    </Blocks>
  </CustomContainer>
);
