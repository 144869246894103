import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts';
import Hero from '../components/shared/Hero';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import IconComboBlocks from '../components/shared/IconComboBlocks';
import { MoreAnchor } from '../components/shared/MoreLink';
import styled from 'styled-components';
import { breakpoints } from '../styles/layout';



const CustomIconComboBlocks = styled(IconComboBlocks)`
  padding: 60px 0;
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 120px 0 180px;
  }
`;

class CollaboratePage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page('Collaborate', {
      title: `Collaborate | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const { data } = this.props;
    const pageData = data.prismicCollaboratePage.data;

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`Collaborate | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_title}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
        />
        <CustomIconComboBlocks
          blocks={pageData.collaborators.map(collaborator => ({
            icon: collaborator.icon,
            title: collaborator.name,
            brief: collaborator.brief.text,
            linkComponent: (
              <MoreAnchor
                href={collaborator.url}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                Visit Website
              </MoreAnchor>
            ),
          }))}
        />
      </Layout>
    );
  }
}


export default CollaboratePage;

export const query = graphql`
  query CollaboratePageQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicCollaboratePage {
      data {
        hero_title
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        
        collaborators {
          name
          url
          icon {
            alt
            copyright
            url
          }
          brief {
            html
            text
          }
        }
      }
    }
  }
`;
